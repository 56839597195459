import { flowRight, get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../common/components/runtime-context';

import { EXPERIMENT_RCE_VIEWER_ANCHORS, EXPERIMENT_USE_FILESUSR_DOMAIN } from '@wix/communities-blog-experiments';

import { isExperimentEnabled } from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';

import * as richContent from '../services/rich-content';
import { getVideoUrl } from '../../common/services/video-utils';
import { getImageUrl } from '../../common/services/image-utils';
import {
  getPostPageFontSizeMobile,
  getPostHeaderTwoFontSizeMobile,
  getPostHeaderThreeFontSizeMobile,
  getPostQuotesFontSizeMobile,
} from '../../common/selectors/app-settings-selectors';
import { getAppSettings } from '../../common/selectors/app-settings-base-selectors';
import { getIsPostPageBundle, getSectionUrl } from '../../common/store/app-config/app-config-selectors';
import { isSeo, isEditor, isPreview, getUrl, isSSR } from '../../common/store/basic-params/basic-params-selectors';
import withHocName from '../../common/hoc/with-hoc-name';
import withDeviceType from '../../common/hoc/with-device-type';
import withRichContentFontClassName from '../../common/hoc/with-rich-content-font-class-name';
import withCardBackgroundColor from '../../common/hoc/with-card-background-color';
import Fullscreen from '../components/rich-content-fullscreen';
// import { getMetaSiteId } from '../../common/store/instance-values/instance-values-selectors';
import withExperiment from '../../common/hoc/with-experiment';

export default function withRCVProps(WrappedComponent) {
  const Wrapper = props => {
    const { RCVProps, RCVActions, BI, ...rest } = props;
    const mappedProps = {
      ...RCVProps,
      ...rest,
      actions: RCVActions,
      bi: BI,
      getVideoUrl,
      getImageUrl,
      emotion: { css: () => '' },
      richContent,
    };

    return <WrappedComponent {...mappedProps} />;
  };

  Wrapper.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    RCVProps: PropTypes.object.isRequired,
    RCVActions: PropTypes.object.isRequired,
    richContentFontClassName: PropTypes.string.isRequired,
  };

  return flowRight(
    withExperiment({
      useFilesusrDomain: EXPERIMENT_USE_FILESUSR_DOMAIN,
    }),
    connect(mapRuntimeToProps),
    withDeviceType,
    withRichContentFontClassName,
    withCardBackgroundColor,
    withHocName('WithRCVProps'),
  )(Wrapper);
}

const mapRuntimeToProps = (state, ownProps, actions) => ({
  RCVProps: {
    viewerKey: computeViewerKey(state),
    appSettings: getAppSettings(state),
    pageUrl: getUrl(state),
    postId: get(ownProps, 'post._id'),
    isOneApp: false,
    enableLinks: true,
    isPostPageEnabled: true,
    isSeo: isSeo(state),
    isSSR: isSSR(state),
    isOOI: true,
    normalize: { disableInlineImages: true },
    isInEditor: () => isEditor(state),
    isInPreview: () => isPreview(state),
    hashtagNavigate: actions.navigateWithinBlog,
    isPostPageBundle: () => getIsPostPageBundle(state),
    addAnchors: isExperimentEnabled(state, EXPERIMENT_RCE_VIEWER_ANCHORS),
    fullscreenComponent: Fullscreen,
    sectionUrl: getSectionUrl(state),
    // iframeSandboxDomain: ownProps.useFilesusrDomain ? `https://${getMetaSiteId(state)}.filesusr.com` : `https://${getMetaSiteId(state)}.usrfiles.com`, todo - commented out as it broke google ads in html plugin
  },
  RCVActions: {
    requestFileDownloadUrl: actions.requestFileDownloadUrlPromisified,
    showMessage: actions.showMessage,
  },
  BI: {
    fileDownloaded: actions.fileDownloaded,
  },
});

const computeViewerKey = state =>
  '' +
  getPostPageFontSizeMobile(state) +
  getPostHeaderTwoFontSizeMobile(state) +
  getPostHeaderThreeFontSizeMobile(state) +
  getPostQuotesFontSizeMobile(state);
